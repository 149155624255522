import React from 'react'
import TextField from '@mui/material/TextField'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker'
import { Grid, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import Slots from './slots'
import { LoginAction } from '../../Actions/LoginAction'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
// import isWeekend from 'date-fns/isWeekend';

let mainContainer = {
  // background: '#fbfbfb',
  // height: '100vh',
  display: 'flex',
  justifyContent: 'center',
}
function SelectDate({
  value,
  setValue,
  setCurrentScreen,
  setTimeslot,
  timeSlot,
  isFinalScreenSubmited,
  timezone,
  CaseID,
  Reschedule,
  finalTimeToShow,
  setFinalTimeToShow
}) {
  const dispatch = useDispatch()
  // const CaseID = useSelector((state) => state.LoginReducer.CaseID) || ''

  const getSlots = (date) => {
    dispatch(LoginAction.getSlotsLoader())
    dispatch(
      LoginAction.getSlots({
        pyID: CaseID,
        // timezone: 'Manager',
        Timezone: timezone,
        actionType: 'AvailableSlot',
        Date: moment(date).format('YYYY/MM/DD'),
        Weekday: moment(date).format('dddd'),
      }),
    )
  }

  return (
    <Grid
      container
      spacing={4}
      style={mainContainer}
      justifyContent="center"
      // alignItems={'center'}
    >
      {!isFinalScreenSubmited && (
        <Grid item xs={10} md={4}>
          <Paper elevation={3}>
            <Box mb={-1} pt={2}>
              <Typography variant="h6">Select Date</Typography>
            </Box>
            <Box p={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  // openTo="days"
                  // loading={true}
                  // showToolbar={true}
                  shouldDisableDate={(day) => {
                    return moment(moment(day).format('YYYY/MM/DD')).isSame(
                      moment(new Date()).format('YYYY/MM/DD'),
                    )
                  }}
                  toolbarTitle={'Select Date'}
                  value={value}
                  disablePast
                  onChange={(newValue) => {
                    setValue(newValue)
                    setTimeslot('')
                    getSlots(newValue)
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
          </Paper>
        </Grid>
      )}

      {value && (
        <Grid item md={isFinalScreenSubmited ? 6 : 4} xs={10}>
          <Slots
            timeSlot={timeSlot}
            setTimeslot={setTimeslot}
            timezone={timezone}
            finalTimeToShow={finalTimeToShow}
            setFinalTimeToShow={setFinalTimeToShow}
            value={value}
            setCurrentScreen={setCurrentScreen}
            isFinalScreenSubmited={isFinalScreenSubmited}
            CaseID={CaseID}
            Reschedule={Reschedule}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default SelectDate
