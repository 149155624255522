import './App.css'
import Login from './components/Login/Login'
import SelectDate from './components/Usercomponents/selectDate'
import React, { useEffect } from 'react'
import {
  Grid,
  Button,
  Tooltip,
  Typography,
  Paper,
  useMediaQuery,
  CircularProgress,
} from '@mui/material'
import './App.scss'
import { useDispatch, useSelector } from 'react-redux'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import { Box } from '@mui/system'
import { LoginAction } from './Actions/LoginAction'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { amber, deepOrange, grey, orange, indigo } from '@mui/material/colors'
import Modal from '@mui/material/Modal'
import Brightness4Icon from '@mui/icons-material/Brightness4'
import Brightness7Icon from '@mui/icons-material/Brightness7'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import moment from 'moment'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  border: '1px solid #ccc',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
}

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === 'dark'
      ? {
          // palette values for light mode
          primary: orange,
          divider: amber[200],
          text: {
            secondary: deepOrange,
          },
        }
      : {
          // palette values for dark mode
          primary: indigo,
          divider: '#272528',
          background: {
            default: '#fff',
            paper: '#fff',
          },
          text: {
            secondary: grey[500],
          },
        }),
  },
})

function App() {
  const [currentScreen, setCurrentScreen] = React.useState('selectDate')
  const [mode, setMode] = React.useState('dark')
  const [value, setValue] = React.useState(new Date())
  const [timeSlot, setTimeslot] = React.useState('')
  const [finalTimeToShow, setFinalTimeToShow] = React.useState('')
  const [timezone, setTimezone] = React.useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  )
  const [inputValue, setInputValue] = React.useState('')

  const getTimezonesLoader = useSelector(
    (state) => state.LoginReducer.getTimezonesLoader,
  )
  // const userName = useSelector((state) => state.LoginReducer.userName) || ''
  const timeZones =
    useSelector((state) => state.LoginReducer.timeZones).map(
      (item) => item.ActualValue,
    ) || []

  const isFinalScreenSubmited =
    useSelector((state) => state.LoginReducer.isFinalScreenSubmited) || false

  let dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const isUserValid = useSelector((state) => state.LoginReducer.isUserValid)
  const CaseID = useSelector((state) => state.LoginReducer.CaseID)
  const userName = useSelector((state) => state.LoginReducer.userName)
  const Reschedule = useSelector((state) => state.LoginReducer.Reschedule)

  // const isUserValid = localStorage.getItem('isUserlogged') || userValid
  // const CaseID = localStorage.getItem('CaseID') || pyID
  // const userName = localStorage.getItem('userName') || name
  // const Reschedule = localStorage.getItem('Reschedule') || isReschedule

  useEffect(() => {
    dispatch(LoginAction.getTimezonesLoader())
    dispatch(LoginAction.getTimezones())
  }, [dispatch])

  const matches = useMediaQuery('(max-width:900px)')

  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode])

  const changeTheme = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'))
  }

  // useEffect(() => {
  //   console.log(isUserValid)
  // }, [isUserValid])

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Paper>
          <Grid
            container
            style={{
              height: '100vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: matches ? 'center' : 'flex-end',
              overflowX: 'hidden',
            }}
          >
            { isUserValid  && (
              <Grid item xs={matches ? 11 : 9}>
                <Box mb={matches ? 2 : 0}>
                  <Grid
                    container
                    justifyContent={
                      isFinalScreenSubmited || !isUserValid
                        ? 'flex-end'
                        : 'space-between'
                    }
                    flexDirection={matches ? 'column-reverse' : 'row'}
                  >
                    {!isFinalScreenSubmited && isUserValid && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'flex-start',
                          alignItems: 'flex-start',
                          // color: '#fff',
                        }}
                      >
                        <Typography>
                          Hi <strong>{userName}</strong>, please select a time
                          slot for a 60 mins meeting.
                        </Typography>
                        <Typography>
                          If none of these work, let the scheduler know by
                          responding to the invitation email.
                        </Typography>
                      </div>
                    )}

                    <Box
                      mr={2}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Tooltip title="Change Theme">
                        <Button
                          size="small"
                          variant="outlined"
                          onClick={changeTheme}
                          color="inherit"
                        >
                          {mode === 'dark' ? (
                            <Brightness7Icon
                              fontSize="small"
                              style={{ color: 'orange' }}
                            />
                          ) : (
                            <Brightness4Icon fontSize="small" />
                          )}
                        </Button>
                      </Tooltip>
                      &nbsp;&nbsp;
                      {isUserValid && (
                        <Tooltip title="Logout">
                          <Button
                            variant="outlined"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              handleOpen()
                            }}
                          >
                            <PowerSettingsNewIcon fontSize="small" />
                          </Button>
                        </Tooltip>
                      )}
                    </Box>
                  </Grid>
                </Box>
              </Grid>
            )}

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography
                  style={{ color: mode === 'dark' ? '#fff' : '#424242' }}
                  variant="subtitle1"
                >
                  Are you sure want to Logout
                </Typography>
                <Box mt={2}>
                  <Grid
                    container
                    justifyContent={'flex-end'}
                    alignItems="center"
                    spacing={1}
                  >
                    <Button
                      onClick={() => {
                        handleClose()
                        dispatch(LoginAction.logOff())
                        localStorage.clear()
                        setCurrentScreen('selectDate')
                        setValue(new Date())
                        setTimeslot('')
                      }}
                    >
                      Yes
                    </Button>
                    <Button
                      style={{ marginLeft: '16px' }}
                      variant="contained"
                      onClick={handleClose}
                    >
                      No
                    </Button>
                  </Grid>
                </Box>
              </Box>
            </Modal>

            {!isUserValid ? (
              <Grid item xs={12}>
                <Login matches={matches} mode={mode} />
              </Grid>
            ) : (
              <React.Fragment>
                {!isFinalScreenSubmited && (
                  <Grid item xs={12} md={12}>
                    <Grid container justifyContent={'center'}>
                      <Grid item md={6}>
                        <Autocomplete
                          value={timezone}
                          fullWidth
                          onChange={(event, newValue) => {
                            setTimeslot('')
                            setTimezone(newValue)
                            if (value && newValue) {
                              dispatch(LoginAction.getSlotsLoader())
                              dispatch(
                                LoginAction.getSlots({
                                  pyID: CaseID,
                                  Timezone: newValue,
                                  actionType: 'AvailableSlot',
                                  Date: moment(value).format('YYYY/MM/DD'),
                                  Weekday: moment(value).format('dddd'),
                                }),
                              )
                            }
                          }}
                          inputValue={inputValue}
                          onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue)
                          }}
                          id="controllable-states-demo"
                          options={timeZones || []}
                          // style={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              label="Select timezone"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {getTimezonesLoader ? (
                  <Grid item xs={12} md={12}>
                    <Grid container justifyContent={'center'}>
                      <Grid item xs={9} md={9}>
                        <Paper
                          style={{
                            height: '50vh',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                          }}
                        >
                          <CircularProgress />
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    {currentScreen === 'selectDate' && (
                      <SelectDate
                        setValue={setValue}
                        matches={matches}
                        value={value}
                        CaseID={CaseID}
                        finalTimeToShow={finalTimeToShow}
                        setFinalTimeToShow={setFinalTimeToShow}
                        Reschedule={Reschedule}
                        timezone={timezone}
                        setTimeslot={setTimeslot}
                        setCurrentScreen={setCurrentScreen}
                        timeSlot={timeSlot}
                        isFinalScreenSubmited={isFinalScreenSubmited}
                      />
                    )}
                  </Grid>
                )}
              </React.Fragment>
            )}
          </Grid>
        </Paper>
      </ThemeProvider>
    </div>
  )
}

export default App
