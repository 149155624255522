
import Noty from 'noty';


export function noty(type, text) {

    if(type === "success"){
      return  new Noty({
            type: "success", 
            text: text,
            theme:'sunset',
            timeout: 6000,
            layout: "topRight",
            closeWith: ['click', 'button'],
          }).show();
    }
    else { 
      return  new Noty({
            type: "error", 
            text: text,
            theme:'sunset',
            timeout: 3000,
            closeWith: ['click', 'button'],
            layout: "topRight"
          }).show();
    }
}